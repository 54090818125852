body{
    background-color: #eee;
}
.mainPortion {
    margin-top: 0px;
    margin-left: 15%;
    background-color: #eee;
    /* height: 680px; */
    padding-top: 3em;
    overflow: hidden;
}