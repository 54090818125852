.header {
    height: 50px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.headerLogo {
    margin-left: 2em;
    padding-top: 0.4em;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
}

.user {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.5em;
    margin-right: 8em;
    font-size: 10px;
    font-weight: bold;
}

.userDetails {
    top: 0;
    right: 0;
    font-size: 1.8vh;
    cursor: pointer;
    margin-top: 1em;
    margin-right: 4em;
    padding-bottom: 2em;
}

.userPic {
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 3em;
    margin-top: 0.5em;
    cursor: pointer;
}

.profileImage {
    border-radius: 7px;
}

.dropdown-content:before {
    position: absolute;
    top: -15px;
    right: 20px;
    display: inline-block;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #999999;
    border-left: 15px solid transparent;
    border-bottom-color: #eee;
    content: '';
}

.dropdown-content:after {
    position: absolute;
    top: -15px;
    right: 20px;
    display: inline-block;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #eee;

    content: '';
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    margin-top: 1em;
    margin-right: 3em;
    background-color: #eee;
    min-width: 160px;
    border-radius: 7px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ddd;
    border-radius: 7px;
}

.userDetails:hover .dropdown-content {
    display: block;
    z-index: 1;
}

@media screen and (max-width: 480px) {
    .user {
        margin-right: 1em;
    }

    .headerLogo {
        margin-left: 1em;
    }
}