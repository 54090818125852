.sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    width: 15%;
    z-index: 1;
    top: 3.4em;
    overflow-x: hidden;
    padding-top: 10px;
    font-weight: bold;
    background-color: #fff;
    -webkit-box-shadow: 9px 0px 7px -6px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 9px 0px 7px -6px rgba(0, 0, 0, 0.67);
    box-shadow: 7px 0px 7px -6px rgba(0, 0, 0, 0.67);
}

.menu {
    cursor: pointer;
    padding: 11px;
    padding-top: 0;
    font-size: 1.6vh;
    border-bottom: 1px solid #ddd;
    transition: border-right 0.3s;
}

.menu:hover {
    background-color: #eee;
    border-right: 5px solid #64bb46;
}

.active {
    background-color: #eee;
    border-right: 5px solid #64bb46;
}

.icon {
    color: #64bb46;
    padding-right: 1em;
    height: 15px;
    margin-top: 15px;
}

@media screen and (max-width: 480px) {
    .sidenav {
        display: none;
    }
}

/* @media screen and (max-width: 1000px) { 
    .sidenav{
        width:22%
    }   
} */
/* 
@media screen and (min-width: 500px and ) { 
    .sidenav{
        width:21%
    }   
} */