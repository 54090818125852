.agentDetails {
    margin-top: 1em;
}

.backArrow {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 1em;
    color: #000;
    font-weight: 100;
    padding: 0 10px 3px 10px;
    border-radius: 50%;
    background-color: #fff !important;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.adminPortalRow {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 14em;
    margin-top: 5.5em;
}

.detailsPortion {
    margin-top: 0;
    display: flex;
    flex-direction: row;
}

.text4 {
    font-weight: bold;
    font-size: medium;
    font-family: Arial;
    color: #64bb46;
}

.ml5 {
    margin-left: 5em;
}

.mr1 {
    margin-right: 1em !important;
}

.activeCard {
    border: 1.5px solid #64bb46 !important;
    transition: border 1s;
}

.editIcon {
    color: #64bb46;
    margin-left: 83%;
    margin-top: 5%;
    height: 15px;
    width: 15px;
}

.settingIcon {
    margin-left: 1em;
    margin-right: 5px;
}

/* .userDetails {
    display: flex;
    flex-direction: row;
} */

.userDetailsCard {
    width: 50%
}

.userPic {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.m-5 {
    margin: 10px;
}

.ml-10 {
    margin-left: 10em;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.boldText {
    font-weight: bold;
}

.rightText {
    float: right;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
}

.rowIndicators {
    display: flex;
    flex-direction: row;
}

.percentage {
    background-color: #888;
    /* margin: 5px 0 5px 0; */
    margin-left: 1em;
    padding: 0 10px 0 10px;
    border-radius: 21px;
    color: #fff;
}

.pointsCards {
    display: flex;
    flex-direction: column;
}

.userName {
    margin-left: 1em;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.businessData {
    padding: 0.7em;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.sm-text {
    font-size: 13px;
}

.md-text {
    font-size: 21px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.calendar {
    cursor: pointer;
}

.search-container {
    position: absolute;
    width: 210px;
    /* display: block; */
    margin: 0 auto;
    /* margin-left: 5px; */
}

.searchBox {
    margin-left: 1em;
    padding: 5px;
}

.rowDataTable {
    margin-left: 5em;
    margin-right: 5em;
}

.rowDataTable table thead tr th:first-child,
.rowDataTable table thead tr th:nth-child(8),
.rowDataTable table tbody tr td:first-child,
.rowDataTable table tbody tr td:nth-child(8) {
    border-right: 1px solid #ECECEC;
}

.rowDataTable .MuiTableCell-head {
    white-space: nowrap;
}

.rowDataTable .MuiTableContainer-root {
    overflow: auto;
    height: calc(100vh - 200px);
}

.rowDataTable table thead tr th:first-child,
.rowDataTable table tbody tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
    outline: 1px solid #ECECEC;
}

.row-bg-success {
    color: #28a745 !important;
}

.row-bg-warning {
    color: #f0ad4e !important;
}

.row-bg-danger {
    color: #d9534f !important;
}

.row-bg-light-warning {
    color: #f66932 !important;
}