.roles{
    float:right
}

.chip{
    border-radius: 3px !important;
    margin:5px;
    background-color: #dcf0d5 !important;
}

.pl-0{
    padding-left:0px !important;
}

.mr-11{
    margin-right: 13em !important;
}

.container {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.display-none{
    display: none;
}

.light-color{
    color:#ddd !important;
}