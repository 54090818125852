body {
  background-color: #eee;
}

.mainPortion {
  margin-top: 5px;
  margin-left: 13.5em;
  background-color: #eee;
  /* height: 680px; */
  overflow: hidden;
}

.upperHeader {
  padding-top: 2.2em;
  margin-left: 5em;
  margin-right: 6%;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.bottomBorder {
  cursor: pointer;
  padding-top: 7px;
  padding: 10px;
  border-bottom: 5px solid #a3d791;
}

.tabText {
  font-size: 13px;
  font-weight: bold;
  padding: 25px;
  text-decoration: none;
  color: #000;
}

.activeTab {
  border-bottom: 5px solid #64bb46;
}

.newBtn {
  position: absolute;
  right: 0;
  margin-right: 5%;
}

.newBtn:focus {
  text-decoration: none;
  outline: 0;
}

.heading {
  color: #64bb46;
  padding-left: 3.5em;
  padding-top: 2em;
  font-size: 1.5em;
  font-weight: bold;
}

.agentSummary {
  padding-top: 1em;
}

.filter {
  position: absolute;
  right: 0;
  margin-right: 20em;
}

.option {
  padding: 7px;
  padding-right: 7px;
  margin-left: 1em;
  border: 1px solid #d0cfce;
  border-radius: 3px;
}

.searchBox {
  margin-left: 1em;
  padding: 5px;
}

.table {
  margin-left: 5em;
  margin-top: 3em;
}

.row {
  margin-left: 5em;
  background-color: #fff;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.2),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border: 1px solid #64bb46;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #64bb46;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tableHead {
  /* font-size: 5px; */
  /* padding-top: 15em; */
  margin-top: 5em;
  border-bottom: 1px solid #aaa;
  margin-left: 5em;
  cursor: pointer;
}

.card {
  margin-top: 1em;
  /* width: 88.5%; */
  width: 293px;
  margin-left: 5em;
  padding: 2.8em 0 0 0;
  cursor: pointer;
}

.card:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.MuiCardActionArea-root {
  width: 100%;
  display: block;
  text-align: right;
}

.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.td {
  padding-right: 5em;
}

.agentName {
  font-size: 0.9em;
}

.agentMonth {
  margin-left: 5%;
}

.headerPoints {
  margin-left: 5%;
}

.agentPoints {
  margin-left: 26%;
  font-size: 9px;
}

.successPoints {
  margin-left: 3.5%;
}

.column {
  display: block;
  /* columns:auto;
    display: table-row;
    column-width: 100px; */
}

.search-container {
  position: absolute;
  width: 200px;
  /* display: block; */
  margin: 0 auto;
  margin-left: 5px;
}

input#search-bar {
  margin: 0 auto;
  width: 100%;
  height: 25px;
  padding: 3px 25px 3px 5px;
  font-size: 1rem;
  border: 1px solid #d0cfce;
  border-radius: 3px;
  outline: none;
}

input#search-bar:focus {
  border: 1px solid;
  transition: 0.35s ease;
}

input#search-bar:focus::-webkit-input-placeholder {
  transition: opacity 0.45s ease;
  opacity: 0;
}

input#search-bar:focus::-moz-placeholder {
  transition: opacity 0.45s ease;
  opacity: 0;
}

input#search-bar:focus:-ms-placeholder {
  transition: opacity 0.45s ease;
  opacity: 0;
}

.search-icon {
  position: relative;
  float: right;
  width: 75px;
  height: 75px;
  top: -52px;
  right: -21px;
}

.row1 {
  display: flex;
  flex-direction: row;
}

/* .col Child  */
.col1 {
  flex: 1;
  /* padding: 1em;
	  margin: .6em;
	  /* important */
  /* color: #333; */
}

.spacingCard {
  display: inline-block;
  position: relative;
  top: -37px;
}

.spacingCardPoints {
  display: inline-block;
  position: relative;
  top: -15px;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.demo {
  width: 50% !important;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 480px) {
  .mainPortion {
    margin-left: 5px;
    width: 100%;
  }
}

.sp-edit-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.MuiPopover-paper {
  box-shadow: 0 1px 17px 2px rgba(0, 0, 0, 0.04) !important
}