.bar{
    margin-top: 5px;
    height:7px !important
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-warning {
    background-color: #f0ad4e !important;
}

.bg-danger {
    background-color: #d9534f !important;
}

.bg-light-warning{
    background-color: #f66932 !important;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
}

.progress {
    display: flex;
    /* height: 1rem; */
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}